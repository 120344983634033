/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EventFilter } from '../models/event-filter';
import { EventDetailSerializerV2 } from '../models/event-detail-serializer-v2';
import { EventCreateSerializerV2 } from '../models/event-create-serializer-v2';
import { EventSetSpeakerType } from '../models/event-set-speaker-type';
@Injectable({
  providedIn: 'root',
})
class EventService extends __BaseService {
  static readonly eventFilterListPath = '/event/filter';
  static readonly eventListListPath = '/event/list';
  static readonly eventListCreatePath = '/event/list';
  static readonly eventScheduleReadPath = '/event/schedule/{id}';
  static readonly eventReadPath = '/event/{id}';
  static readonly eventUpdatePath = '/event/{id}';
  static readonly eventPartialUpdatePath = '/event/{id}';
  static readonly eventDeletePath = '/event/{id}';
  static readonly eventSetSpeakerTypeCreatePath = '/event/{id}/set_speaker_type';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EventService.EventFilterListParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `time_start`: time_start
   *
   * - `time_finish`: time_finish
   *
   * - `theme`: theme
   *
   * - `thematics`: thematics
   *
   * - `speakers`: speakers
   *
   * - `search`: search
   *
   * - `recommended`: recommended
   *
   * - `is_free`: is_free
   *
   * - `ids`: ids
   *
   * - `date`: date
   *
   * - `area_events`: area_events
   *
   * - `area`: area
   */
  eventFilterListResponse(params: EventService.EventFilterListParams): __Observable<__StrictHttpResponse<EventFilter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.timeStart != null) __params = __params.set('time_start', params.timeStart.toString());
    if (params.timeFinish != null) __params = __params.set('time_finish', params.timeFinish.toString());
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.thematics != null) __params = __params.set('thematics', params.thematics.toString());
    if (params.speakers != null) __params = __params.set('speakers', params.speakers.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.recommended != null) __params = __params.set('recommended', params.recommended.toString());
    if (params.isFree != null) __params = __params.set('is_free', params.isFree.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    if (params.areaEvents != null) __params = __params.set('area_events', params.areaEvents.toString());
    if (params.area != null) __params = __params.set('area', params.area.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventFilter>;
      })
    );
  }
  /**
   * @param params The `EventService.EventFilterListParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `time_start`: time_start
   *
   * - `time_finish`: time_finish
   *
   * - `theme`: theme
   *
   * - `thematics`: thematics
   *
   * - `speakers`: speakers
   *
   * - `search`: search
   *
   * - `recommended`: recommended
   *
   * - `is_free`: is_free
   *
   * - `ids`: ids
   *
   * - `date`: date
   *
   * - `area_events`: area_events
   *
   * - `area`: area
   */
  eventFilterList(params: EventService.EventFilterListParams): __Observable<EventFilter> {
    return this.eventFilterListResponse(params).pipe(
      __map(_r => _r.body as EventFilter)
    );
  }

  /**
   * @param params The `EventService.EventListListParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `time_start`: time_start
   *
   * - `time_finish`: time_finish
   *
   * - `theme`: theme
   *
   * - `thematics`: thematics
   *
   * - `speakers`: speakers
   *
   * - `search`: search
   *
   * - `recommended`: recommended
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `is_free`: is_free
   *
   * - `ids`: ids
   *
   * - `date`: date
   *
   * - `area_events`: area_events
   *
   * - `area`: area
   */
  eventListListResponse(params: EventService.EventListListParams): __Observable<__StrictHttpResponse<Array<EventDetailSerializerV2>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.timeStart != null) __params = __params.set('time_start', params.timeStart.toString());
    if (params.timeFinish != null) __params = __params.set('time_finish', params.timeFinish.toString());
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.thematics != null) __params = __params.set('thematics', params.thematics.toString());
    if (params.speakers != null) __params = __params.set('speakers', params.speakers.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.recommended != null) __params = __params.set('recommended', params.recommended.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.isFree != null) __params = __params.set('is_free', params.isFree.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    if (params.areaEvents != null) __params = __params.set('area_events', params.areaEvents.toString());
    if (params.area != null) __params = __params.set('area', params.area.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EventDetailSerializerV2>>;
      })
    );
  }
  /**
   * @param params The `EventService.EventListListParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `time_start`: time_start
   *
   * - `time_finish`: time_finish
   *
   * - `theme`: theme
   *
   * - `thematics`: thematics
   *
   * - `speakers`: speakers
   *
   * - `search`: search
   *
   * - `recommended`: recommended
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `is_free`: is_free
   *
   * - `ids`: ids
   *
   * - `date`: date
   *
   * - `area_events`: area_events
   *
   * - `area`: area
   */
  eventListList(params: EventService.EventListListParams): __Observable<Array<EventDetailSerializerV2>> {
    return this.eventListListResponse(params).pipe(
      __map(_r => _r.body as Array<EventDetailSerializerV2>)
    );
  }

  /**
   * @param data undefined
   */
  eventListCreateResponse(data: EventCreateSerializerV2): __Observable<__StrictHttpResponse<EventCreateSerializerV2>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/event/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventCreateSerializerV2>;
      })
    );
  }
  /**
   * @param data undefined
   */
  eventListCreate(data: EventCreateSerializerV2): __Observable<EventCreateSerializerV2> {
    return this.eventListCreateResponse(data).pipe(
      __map(_r => _r.body as EventCreateSerializerV2)
    );
  }

  /**
   * @param params The `EventService.EventScheduleReadParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this event.
   *
   * - `type`: type
   *
   * - `time_start`: time_start
   *
   * - `time_finish`: time_finish
   *
   * - `theme`: theme
   *
   * - `thematics`: thematics
   *
   * - `speakers`: speakers
   *
   * - `search`: search
   *
   * - `recommended`: recommended
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `is_free`: is_free
   *
   * - `ids`: ids
   *
   * - `f`: schedule format
   *
   * - `date`: date
   *
   * - `area_events`: area_events
   *
   * - `area`: area
   */
  eventScheduleReadResponse(params: EventService.EventScheduleReadParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.timeStart != null) __params = __params.set('time_start', params.timeStart.toString());
    if (params.timeFinish != null) __params = __params.set('time_finish', params.timeFinish.toString());
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.thematics != null) __params = __params.set('thematics', params.thematics.toString());
    if (params.speakers != null) __params = __params.set('speakers', params.speakers.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.recommended != null) __params = __params.set('recommended', params.recommended.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.isFree != null) __params = __params.set('is_free', params.isFree.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.f != null) __params = __params.set('f', params.f.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    if (params.areaEvents != null) __params = __params.set('area_events', params.areaEvents.toString());
    if (params.area != null) __params = __params.set('area', params.area.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event/schedule/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EventService.EventScheduleReadParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this event.
   *
   * - `type`: type
   *
   * - `time_start`: time_start
   *
   * - `time_finish`: time_finish
   *
   * - `theme`: theme
   *
   * - `thematics`: thematics
   *
   * - `speakers`: speakers
   *
   * - `search`: search
   *
   * - `recommended`: recommended
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `is_free`: is_free
   *
   * - `ids`: ids
   *
   * - `f`: schedule format
   *
   * - `date`: date
   *
   * - `area_events`: area_events
   *
   * - `area`: area
   */
  eventScheduleRead(params: EventService.EventScheduleReadParams): __Observable<null> {
    return this.eventScheduleReadResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  eventReadResponse(id: string): __Observable<__StrictHttpResponse<EventDetailSerializerV2>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/event/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventDetailSerializerV2>;
      })
    );
  }
  /**
   * @param id undefined
   */
  eventRead(id: string): __Observable<EventDetailSerializerV2> {
    return this.eventReadResponse(id).pipe(
      __map(_r => _r.body as EventDetailSerializerV2)
    );
  }

  /**
   * @param params The `EventService.EventUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventUpdateResponse(params: EventService.EventUpdateParams): __Observable<__StrictHttpResponse<EventCreateSerializerV2>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/event/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventCreateSerializerV2>;
      })
    );
  }
  /**
   * @param params The `EventService.EventUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventUpdate(params: EventService.EventUpdateParams): __Observable<EventCreateSerializerV2> {
    return this.eventUpdateResponse(params).pipe(
      __map(_r => _r.body as EventCreateSerializerV2)
    );
  }

  /**
   * @param params The `EventService.EventPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventPartialUpdateResponse(params: EventService.EventPartialUpdateParams): __Observable<__StrictHttpResponse<EventCreateSerializerV2>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/event/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventCreateSerializerV2>;
      })
    );
  }
  /**
   * @param params The `EventService.EventPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventPartialUpdate(params: EventService.EventPartialUpdateParams): __Observable<EventCreateSerializerV2> {
    return this.eventPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as EventCreateSerializerV2)
    );
  }

  /**
   * @param id undefined
   */
  eventDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/event/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  eventDelete(id: string): __Observable<null> {
    return this.eventDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EventService.EventSetSpeakerTypeCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventSetSpeakerTypeCreateResponse(params: EventService.EventSetSpeakerTypeCreateParams): __Observable<__StrictHttpResponse<EventSetSpeakerType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/event/${encodeURIComponent(String(params.id))}/set_speaker_type`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventSetSpeakerType>;
      })
    );
  }
  /**
   * @param params The `EventService.EventSetSpeakerTypeCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  eventSetSpeakerTypeCreate(params: EventService.EventSetSpeakerTypeCreateParams): __Observable<EventSetSpeakerType> {
    return this.eventSetSpeakerTypeCreateResponse(params).pipe(
      __map(_r => _r.body as EventSetSpeakerType)
    );
  }
}

module EventService {

  /**
   * Parameters for eventFilterList
   */
  export interface EventFilterListParams {

    /**
     * type
     */
    type?: string;

    /**
     * time_start
     */
    timeStart?: string;

    /**
     * time_finish
     */
    timeFinish?: string;

    /**
     * theme
     */
    theme?: string;

    /**
     * thematics
     */
    thematics?: string;

    /**
     * speakers
     */
    speakers?: string;

    /**
     * search
     */
    search?: string;

    /**
     * recommended
     */
    recommended?: string;

    /**
     * is_free
     */
    isFree?: string;

    /**
     * ids
     */
    ids?: string;

    /**
     * date
     */
    date?: string;

    /**
     * area_events
     */
    areaEvents?: string;

    /**
     * area
     */
    area?: string;
  }

  /**
   * Parameters for eventListList
   */
  export interface EventListListParams {

    /**
     * type
     */
    type?: string;

    /**
     * time_start
     */
    timeStart?: string;

    /**
     * time_finish
     */
    timeFinish?: string;

    /**
     * theme
     */
    theme?: string;

    /**
     * thematics
     */
    thematics?: string;

    /**
     * speakers
     */
    speakers?: string;

    /**
     * search
     */
    search?: string;

    /**
     * recommended
     */
    recommended?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * is_free
     */
    isFree?: string;

    /**
     * ids
     */
    ids?: string;

    /**
     * date
     */
    date?: string;

    /**
     * area_events
     */
    areaEvents?: string;

    /**
     * area
     */
    area?: string;
  }

  /**
   * Parameters for eventScheduleRead
   */
  export interface EventScheduleReadParams {

    /**
     * A unique integer value identifying this event.
     */
    id: number;

    /**
     * type
     */
    type?: string;

    /**
     * time_start
     */
    timeStart?: string;

    /**
     * time_finish
     */
    timeFinish?: string;

    /**
     * theme
     */
    theme?: string;

    /**
     * thematics
     */
    thematics?: string;

    /**
     * speakers
     */
    speakers?: string;

    /**
     * search
     */
    search?: string;

    /**
     * recommended
     */
    recommended?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * is_free
     */
    isFree?: string;

    /**
     * ids
     */
    ids?: string;

    /**
     * schedule format
     */
    f?: 'pdf' | 'html';

    /**
     * date
     */
    date?: string;

    /**
     * area_events
     */
    areaEvents?: string;

    /**
     * area
     */
    area?: string;
  }

  /**
   * Parameters for eventUpdate
   */
  export interface EventUpdateParams {
    id: string;
    data: EventCreateSerializerV2;
  }

  /**
   * Parameters for eventPartialUpdate
   */
  export interface EventPartialUpdateParams {
    id: string;
    data: EventCreateSerializerV2;
  }

  /**
   * Parameters for eventSetSpeakerTypeCreate
   */
  export interface EventSetSpeakerTypeCreateParams {
    id: string;
    data: EventSetSpeakerType;
  }
}

export { EventService }
